<template>
  <div class="about font-medium main-frame" :class="isEn?'about-en':''">
    <!-- 内容1 -->
    <div class="cont-1 relative">
      <!-- <el-image  lazy   v-if="isEn" :src="require('../assets/product/11-en.jpg')" :alt="$t('zh1_1')" class='banner-1'/> -->
      <el-image  lazy :src="require('../assets/about/banner.jpg')" class='banner-1'/>
      <div class="text-1" >
        <h2 class="h-1 fz48 right">{{$t("ah_1")}}</h2>
        <div class="con-1-box">
          <div class="p-con left">
            <p class="p2 fz14 gapB20">
              {{$t("ah_p1")}}
            </p>
            <p class="p2 fz14">
              {{$t("ah_p2")}}
            </p>
          </div>
          <img :src="require('../assets/about/linear.png')" class='linear'/>
        </div>
      </div>
    </div>

    <!-- 内容2 -->
    <div class="cont-2 padd92-108">
      <h2 class="h-1 gapB64">{{$t("ah_2")}}</h2>
      <h3 class="h-3 gapB40">{{$t("ah2_1")}}</h3>
      <ul class="ul-content gapB64">
        <li v-for="(item,index) in govs" :class="{ mr20: index<govs.length-1,gov:true}" :key="index">
          <img :src="item.imgUrl" alt=""/>    
        </li>
      </ul>
      <h3 class="h-3 gapB40">{{$t("ah2_2")}}</h3>
      <ul class="ul-content gapB20">
        <li v-for="(item,index) in top500List1" :class="{ mr20: index<top500List1.length-1,top500:true}" :key="index">
          <img :src="item.imgUrl" alt=""/>    
        </li>
      </ul>
      <ul class="ul-content gapB64">
        <li v-for="(item,index) in top500List2" :class="{ mr20: index<top500List2.length-1,top500:true}" :key="index">
          <img :src="item.imgUrl" alt=""/>    
        </li>
      </ul>
      <h3 class="h-3 gapB40">{{$t("ah2_3")}}</h3>
      <ul class="ul-content gapB20">
        <li v-for="(item,index) in investor1" :class="{ mr20: index<investor1.length-1,investor:true}" :key="index">
          <img :src="item.imgUrl" alt=""/>    
        </li>
      </ul>
      <ul class="ul-content">
        <li v-for="(item,index) in investor2" :class="{ mr20: index<investor2.length-1,investor:true}" :key="index">
          <img :src="item.imgUrl" alt=""/>    
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about',
  watch: {
    
  },
  data() {
    return {
      govs: [
        {
          imgUrl:require('../assets/about/gov1.png'),
        },
        {
          imgUrl:require('../assets/about/gov2.png'),
        },
      ],
      top500List1: [
        {
          imgUrl:require('../assets/about/top500_1.png'),
        },
        {
          imgUrl:require('../assets/about/top500_2.png'),
        },
        {
          imgUrl:require('../assets/about/top500_3.png'),
        },
      ],
      top500List2: [
        {
          imgUrl:require('../assets/about/top500_4.png'),
        },
        {
          imgUrl:require('../assets/about/top500_5.png'),
        },
        {
          imgUrl:require('../assets/about/top500_6.png'),
        },
      ],
      investor1: [
        {
          imgUrl:require('../assets/about/investor_1.png'),
        },
        {
          imgUrl:require('../assets/about/investor_2.png'),
        },
        {
          imgUrl:require('../assets/about/investor_3.png'),
        },
        {
          imgUrl:require('../assets/about/investor_4.png'),
        },
      ],
      investor2: [
        {
          imgUrl:require('../assets/about/investor_5.png'),
        },
        {
          imgUrl:require('../assets/about/investor_6.png'),
        },
        {
          imgUrl:require('../assets/about/investor_7.png'),
        },
        {
          imgUrl:require('../assets/about/investor_8.png'),
        },
        {
          imgUrl:require('../assets/about/investor_9.png'),
        },
      ],
    };
  },
  computed: {
    isEn:function(){
      return this.$store.state.isEn
    }
  },
  mounted() {
    
  },
  methods: {
    
  }
};
</script>
<style lang="scss" scoped>
.about {
  .banner-1{
    height: 800px;
  }
  .padd92-108{
    padding-top: 92px;
    padding-bottom: 108px;
  }
  .linear{
    width: 248px;
    height: 108px;
  }
  .cont-1{
    .text-1{
      width: 1200px;
      height: 390px;
      position: absolute;
      top: 188px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translate(-50%);
      -ms-transform: translate(-50%);
      -o-transform: translate(-50%);
      transform: translate(-50%);
      .h-1{
        line-height: 56px;
        padding: 32px 216px 8px 0;
      }
      .con-1-box{
        display: flex;
        .p-con{
          width: 740px;
          padding-top: 36px;
          padding-right: 20px;
          .p2{
            line-height: 28px;
          }
        }
      }
    }
  }
  .cont-2{
    .ul-content{
      width: 100%;
      display: flex;
      justify-content: center;
      li{
        padding: 16px;
        box-sizing: border-box;
        background-image: url(../assets/about/company_bg.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        border-radius: 4px;
        box-shadow: 0px 1px 2px 0px rgba(69,77,92,0.03), 0px 3px 16px 0px rgba(69,77,92,0.03), 0px 20px 70px 0px rgba(69,77,92,0.06);
      }
      .gov{
        width: 320px;
        height: 192px;
        img{
          width: 288px;
          height: 160px;
        }
      }
      .top500{
        width: 280px;
        height: 168px;
        img{
          width: 248px;
          height: 136px;
        }
      }
      .investor{
        width: 240px;
        height: 144px;
        img{
          width: 208px;
          height: 112px;
        }
      }
      .mr20{
        margin-right: 20px;
      }
    }
  }
}
@media (max-width: 1440px) {
  
}
@media (max-width: 1280px) {
  
}
@media (max-width: 992px) {
  
}
@media (max-width: 768px) {
  
}
@media (max-width: 428px) {
  
}
</style>
